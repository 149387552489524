import clsx from "clsx";
import Link from "next/link";
import React, { useState } from "react";
import styles from "./footer.module.css";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useUserContext } from "../shared/user-context";

type LocaleType = "ko" | "en" | "zh";
const openI18n = true;
export default function KirdFooter() {
  const { t } = useTranslation("footer");
  const { push, pathname, query, asPath } = useRouter();
  const { user } = useUserContext();
  const [url, setUrl] = useState<string>("");
  const btnRef = React.useRef<HTMLButtonElement>(null);

  const fnFamily = (event: any) => {
    setUrl(event.target.value);
    btnRef.current.focus();
  };

  const move = () => {
    if (window && url !== "") {
      window.open(url, "_blank");
    }
  };

  const changeLocale = (type: LocaleType) => {
    push({ pathname, query }, asPath, {
      scroll: false,
      shallow: false,
      locale: type,
    });
  };

  return (
    <React.Fragment>
      {/* PC */}
      <div className={styles.lytFooter}>
        <div className={styles.footerArea}>
          <div className={styles.footerLogo}>
            <Link href="/">KIRD 국가과학기술인력개발원</Link>
          </div>
          <div className={styles.footerMenu}>
            <ul className={styles.menuGroup}>
              <li className={styles.menuList}>
                <Link href="/info/agreement">{t("이용약관")}</Link>
              </li>
              <li className={styles.menuList}>
                <Link href="https://www.kird.re.kr/info/personalPolicy">
                  <a className={styles.highLight}>{t("개인정보처리방침")}</a>
                </Link>
              </li>
              <li className={styles.menuList}>
                <Link href="/info/emailPolicy">
                  {t("이메일 무단수집 거부")}
                </Link>
              </li>
              <li className={styles.menuList}>
                <Link href="/info/boardPolicy">{t("게시판 운영원칙")}</Link>
              </li>
            </ul>
            <div className={styles.address}>
              <span>
                {t(
                  "(28116) 충청북도 청주시 청원구 오창읍 양청4길 45 국가과학기술인력개발원"
                )}
              </span>
              <br />
              <span>TEL. 1588-5834 FAX. 043-251-7063</span>
            </div>
            <div className={styles.addressMob}>
              <p>
                {t(
                  "(28116) 충청북도 청주시 청원구 오창읍 양청4길 45"
                )}
              </p>
              <p>
                {t(
                  "국가과학기술인력개발원"
                )}
              </p>
              <span>TEL. 1588-5834 FAX. 043-251-7063</span>
            </div>
          </div>
          <div className={styles.footerQuick}>
            <ul className={styles.quickSns}>
              <li>
                <a href="https://blog.naver.com/keyrdream" target="_blank">
                  {t("naver 바로가기")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCOiPUleeCt89_1mNlJRFbTg"
                  target="_blank"
                >
                  {t("유튜브 바로가기")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/officialkird/"
                  target="_blank"
                >
                  {t("페이스북 바로가기")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kird_official/?igshid=1jm1ute74hfa1"
                  target="_blank"
                >
                  {t("인스타그램 바로가기")}
                </a>
              </li>
            </ul>
            <div className={styles.quickFamily}>
              <select
                className={styles.quickSelect}
                title={t("패밀리사이트 - 선택시 새 창 이동")}
                onChange={fnFamily}
                defaultValue=""
              >
                <option value="">
                  {t("패밀리사이트")}
                </option>
                <option value="https://www.kird.re.kr/">
                  {t("KIRD 홈페이지")}
                </option>
                <option value="https://k-club.kird.re.kr/">
                  {t("K-클럽")}
                </option>
              </select>
              {/* 해당 input 클릭 시 패밀리사이트로 이동되어야 함 */}
              <button
                type="button"
                ref={btnRef}
                onClick={move}
                className={styles.quickFamilyBtn}
                value="이동"
                title="새창으로 이동"
                disabled={url ? false : true}
                style={url ? { cursor: "pointer" } : { cursor: "not-allowed" }}
              >
                이동
              </button>
            </div>
            {/* <div style={{ color: "#ffffff", marginTop: "1em" }}>
              <div style={{ color: "#ffffff", marginTop: "1em" }}>
                {openI18n && (
                  <React.Fragment>
                    {" "}
                    <span
                      className="cursor-pointer"
                      onClick={() => changeLocale("en")}
                    >
                      English
                    </span>{" "}
                    |{" "}
                  </React.Fragment>
                )}
                {openI18n && (
                  <span
                    className="cursor-pointer"
                    onClick={() => changeLocale("ko")}
                  >
                    한국어
                  </span>
                )}
              </div>
            </div> */}
          </div>
          <div className={styles.footerCopy}>
            <span>
              {t("2015 KIRD 국가과학기술인력개발원 All Right reserved")}
            </span>
            <br />
            <span>
              {t("사업자등록번호")}. 314-82-17255 {t("통신판매업신고")} :{" "}
              {t("제2014-대전유성-0021호")}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.lytFooterM}>
        <div className={styles.footerArea}>
          <div className={styles.footerMenu}>
            <ul className={styles.menuGroup}>
              <li className={clsx(styles.menuList, styles.home)}>
                <Link href="/">
                  <a>{t("홈")}</a>
                </Link>
              </li>
              <li className={clsx(styles.menuList, styles.search)}>
                <Link href="/explore">
                  <a>{t("탐색")}</a>
                </Link>
              </li>
              <li className={clsx(styles.menuList, styles.study)}>
                {user?.authenticated ? (
                  <Link href="/study">
                    <a>{t("학습")}</a>
                  </Link>
                ) : (
                  <Link href="#">
                  <a>{t("학습")}</a>
                  </Link>
                )}
              </li>
              <li className={clsx(styles.menuList, styles.subscrip)}>
                <Link href="/subscription/home">
                  <a>{t("구독")}</a>
                </Link>
              </li>
              <li className={clsx(styles.menuList, styles.my)}>
                <Link href="/my/history">
                  <a>{user?.id ? "MY" : "로그인"}</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
